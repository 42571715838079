
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import EditContactsModal from "@/components/modals/crm_forms/EditContactsModal.vue";

export default defineComponent({
  props: 
  {
      id: { required: true }
  },
  name: "apps-crm-contacts-details",
  components: {
   EditContactsModal
  },
  methods: {
    
  },
  setup(props) {

    var ids = ref(props.id);
    const store = useStore();

   interface ViewCategories {
      contact_id,
      contact_name,
      contact_designation_name,
      contact_email,
      contact_mobile_no,
      contact_landline_no,
      address_line_1,
      address_line_2,
    //   pincode,
    //   city_name,
    //   state_name,
      active : {
        label :string;
        color :string;
      }
    };    
    
    var resultsView = ref<ViewCategories>();
  
    resultsView.value = {
      contact_id: "",
      contact_name: "",
      contact_designation_name: "",
      contact_email: "",
      contact_mobile_no: "",
      contact_landline_no: "",
      address_line_1: "",
      address_line_2: "",
    //   pincode : "",
    //   city_name : "",
    //   state_name : "",
      active: {
        label: "",
        color: "",
      }
    }

    const getGradeDetails = async () => {
     
      try {

      var values = { "contact_id"  :  props.id, "page"  : 1, "records_per_page" : 1}

      await store.dispatch(Actions.CUST_LIST_CONTACT, values).then(({ data }) => {

        var status_label = ""
        var status_color = ""
        var active_label = ""
        var active_color = ""

        if (data.active){
            active_label = "Yes";
            active_color = "success";                  
        }else{
            active_label = "No";
            active_color = "danger";
        }
     
        resultsView.value = {
          contact_id :  data.contact_id,
          contact_name : data.contact_name,
          contact_designation_name: data.contact_designation_name,
          contact_email : data.contact_email,
          contact_mobile_no : data.contact_mobile_no,
          contact_landline_no : data.contact_landline_no,
          address_line_1 : data.address_line_1,
          address_line_2 : data.address_line_2,
        //   pincode : data.pincode_id,
        //   city_name : data.city_id,
        //   state_name : data.state_id, 
          active: {
            label: active_label,
            color: active_color
          },
        }
      console.log(resultsView)
              

      })
      .catch(({ response }) => {

        console.log(response);

      });
        
      } catch (e) {
        console.log(e);
      }
    };        
    
    
    onMounted( async () => {
      await getGradeDetails();
      setCurrentPageBreadcrumbs("Contact Details", ["Contact"]);
    });


    return {
      resultsView,
      ids,
    };

  }
});
