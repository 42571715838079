
import { defineComponent, ref, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import axios from "axios";
import { useRouter } from "vue-router";
import { mask } from "vue-the-mask";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { length, stage, form } from "@/core/data/genericData";
import { active } from "@/core/data/genericData";

export default defineComponent({
  name: "edit-contact-modal",
  directives: { mask },
  props: {
    data: {
      type: Object,
    },
  },
  components: {},

  setup(props) {
    const formRef = ref<null | HTMLFormElement>(null);
    const editContactModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const loadingPin = ref<boolean>(false);
    const store = useStore();
    const router = useRouter();
    const designation = ref([]);
    const length_list_data = length;
    const form_list_data = form;
    const stage_list_data = stage;
    const active_list_data = active;
    var formData = ref({});
    const branch = ref([]);
    onMounted(async () => {
      await setDesignationdata(designation.value);
      await setCompanyData(company.value);
      await setFormData(props.data);
    });

    const OnClickCompany = async (compid) => {
      try {
        var values = { company_id: compid, page: 1, records_per_page: 10 };
        await store
          .dispatch(Actions.CUST_GET_BRANCH_LIST, values)
          .then(({ data }) => {
            branch.value = data.result_list;
            //loading.value = false;
            console.log(branch.value);
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const getPincodeData = async () => {
      loadingPin.value = true;
      const db_data = { search_term: formData.value["pincode"] };
      await store
        .dispatch(Actions.CUST_GET_PINCODE, db_data)
        .then(({ data }) => {
          if (data) {
            formData.value["city"] = data["city_name"];
            formData.value["state"] = data["state_name"];
            formData.value["country"] = "India";
            formData.value["city_id"] = data["city_id"];
            formData.value["stateid"] = data["state_id"];
            formData.value["pincodeid"] = data["pincode_id"];
            formData.value["countryid"] = "1";
            loadingPin.value = false;
          } else {
            formData.value["city"] = "";
            formData.value["state"] = "";
            formData.value["country"] = "";
            formData.value["city_id"] = "";
            formData.value["state_id"] = "";
            formData.value["pincode"] = "";
            formData.value["country_id"] = "";

            Swal.fire({
              text: "Sorry, It's Invalid Pincode!",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            loadingPin.value = false;
          }
        })
        .catch(({ response }) => {
          Swal.fire({
            text: "Sorry, It's Invalid Pincode!",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          loadingPin.value = false;
        });
    };

    const setDesignationdata = async (data) => {
      const db_data = {};
      //loading.value = true;
      try {
        await store
          .dispatch(Actions.CUST_DESIGNATION_CONTACT, db_data)
          .then(({ body }) => {
            designation.value = body.business_natures;
            //loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const company = ref([]);
    const setCompanyData = async (data) => {
      const db_data = {};
      //loading.value = true;
      try {
        var values = { company_id: 0, page: 1, records_per_page: 10 };
        await store
          .dispatch(Actions.CUST_GET_COMPANY_LIST, values)
          .then(({ data }) => {
            company.value = data.result_list;
            //loading.value = false;
            console.log(company.value);
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const rules = ref({
      name: [
        {
          required: true,
          message: "Contact Name is required",
          trigger: "change",
          pattern: "^[a-zA-Z]+$",
        },
      ],
      company_select: [
        {
          required: true,
          message: "Company is required",
          trigger: "change",
        },
      ],
      branch_select: [
        {
          required: true,
          message: "Branch is required",
          trigger: "change",
        },
      ],
      // designation_select: [
      //   {
      //     required: true,
      //     message: "Designation is required",
      //     trigger: "change",
      //   }
      // ],
      // email_select: [
      //   {
      //     required: true,
      //     message: "Email Form is required",
      //     trigger: "change",
      //   }
      // ],
      // mobile_select: [
      //   {
      //     required: true,
      //     message: "Mobile No. is required",
      //     trigger: "change",
      //     min : 10,
      //     max : 10,
      //   }
      // ],
      // landline_select: [
      //   {
      //     required: true,
      //     message: "Landline No. is required",
      //     trigger: "change",
      //   }
      // ],
      address_select: [
        {
          required: true,
          message: "Address Line 1 is required",
          trigger: "click",
        },
      ],
      pincode_select: [
        {
          required: true,
          message: "Pincode is required",
          trigger: "click",
        },
      ],
    });

    const setFormData = async (data) => {
      console.log(data.ids);
      var j = 0;
      try {
        var values = { contact_id: data.ids, page: 1, records_per_page: 1 };
        await store
          .dispatch(Actions.CUST_LIST_CONTACT, values)
          .then(({ data }) => {
            formData.value = {
              name: data.contact_name,
              id: data.contact_id,
              company_select: data.company_id,
              branch_select: data.company_branch_id,
              designation_select: data.contact_designation_id,
              email_select: data.contact_email,
              mobile_select: data.contact_mobile_no,
              landline_select: data.contact_landline_no,
              address_select: data.address_line_1,
              address2_select: data.address_line_2,
              pincode: data.pincode_id,
              pincodeid: data.pincode_id,
              city: data.city_id,
              cityid: data.city_id,
              state: data.state_id,
              stateid: data.state_id,
              country: "India",
              active: data.active,
            };
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const setContactData = async (data) => {
      const db_data = {
        contact_id: data.id,
        contact_name: data.name,
        company: data.company_select,
        company_branch: data.branch_select,
        designation_id: data.designation_select,
        email: data.email_select,
        mobile_no: data.mobile_select,
        landline_no: data.landline_select,
        address_line_1: data.address_select,
        address_line_2: data.address2_select,
        pincode_id: data.pincode_id,
        city_id: data.city_id,
        state_id: data.state_id,
        country_id: "1",
        //"created_ip": "0.0.0.0",
        //"created_uagent": "Test",
        //"created_user_id": "1",
        active: data.active,
        verified_yes_no: 0,
      };

      await store
        .dispatch(Actions.CUST_UPDATE_CONTACT, db_data)
        .then(({ data }) => {
          if (data) {
            setTimeout(() => {
              loading.value = false;

              Swal.fire({
                text: "Contact has been successfully created.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(editContactModalRef.value);
                router.go(0);
              });
            }, 2000);
          } else {
            loading.value = false;
            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            return false;
          }
        })
        .catch(({ response }) => {
          console.log(response);
        });
    };

    const submit = async () => {
      loading.value = true;
      if (!formRef.value) {
        loading.value = false;
        return;
      }
      formRef.value.validate(async (valid) => {
        if (valid) {
          await setContactData(formData.value);
        } else {
          loading.value = false;
          return false;
        }
      });
    };

    return {
      formData,
      rules,
      submit,
      formRef,
      loading,
      editContactModalRef,
      OnClickCompany,
      setDesignationdata,
      setCompanyData,
      getPincodeData,
      designation,
      branch,
      company,
      parent,
      loadingPin,
      length_list_data,
      stage_list_data,
      form_list_data,
      active_list_data,
    };
  },
});
